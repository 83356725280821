<template>
  <div>兑换记录</div>
</template>

<script>
export default {}
</script>

<style>
</style>
